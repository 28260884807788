import { Component } from '@angular/core'
import { NavController } from '@ionic/angular'

import { LogInfoDto } from '../service/dto/log.info.dto'
import { PaymentCompanyDetailDto } from '../service/dto/payment.company.detail.dto'
import { NotificationManager } from '../business/notification.manager'

@Component({
  templateUrl: 'log.page.html'
})
export class LogPage {
  from: Date = new Date()
  to: Date = new Date()
  logs: LogInfoDto[]
  paymentCompanies: PaymentCompanyDetailDto[]
  paymentCompany: PaymentCompanyDetailDto
  branch: string
  host: string

  constructor(private manager: NotificationManager) {
    manager.allPaymentCompanies()
      .subscribe((paymentCompanies) => {
         this.paymentCompanies = paymentCompanies
      })
  }

  clearCompany() {
    this.paymentCompany = null
  }

  search() {
    let companyId = this.paymentCompany ? this.paymentCompany.id : null
    let branch = this.branch != "" ? this.branch : null
    let host = this.host != "" ? this.host : null
    this.manager.getLog(this.from,
        this.to,
        companyId,
        branch,
        host)
      .subscribe((logs) => {
         this.logs = logs
      })
  }

  format(str: string) {
    try {
      let d = new Date(str)
      return `${pad(d.getDate())}/${pad(d.getMonth() + 1)}/${d.getFullYear()} ${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}`
    } catch (e) {
      return str
    }
  }
}

function pad(value: any) : string {
  let str = "00" + value
  return str.substring(str.length - 2)
}
