import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { FormGroup } from '@angular/forms'
import { NavController } from '@ionic/angular'

import { Session } from 'system-frontend'
import { ask, buildFormGroup, updateFormGroup, updateModel } from 'system-frontend'

import { PaymentTypeDto } from '../service/dto/payment.type.dto'
import { NotificationManager } from '../business/notification.manager'
import { UPDATE_PAYMENT_TYPE } from '../business/notification.functions'

@Component({
  templateUrl: 'payment.type.edit.page.html'
})
export class PaymentTypeEditPage {
  paymentType: PaymentTypeDto
  enabled: boolean = true
  canUpdate: boolean
  formGroup: FormGroup

  constructor(router: Router,
              private navController: NavController,
              private session: Session,
              private manager: NotificationManager) {
      this.formGroup = buildFormGroup("name")
      if (router.getCurrentNavigation().extras) {
        this.paymentType = router.getCurrentNavigation().extras as PaymentTypeDto
      } else {
        this.paymentType = new PaymentTypeDto()
      }
      updateFormGroup(this.paymentType, this.formGroup)
  }

  ionViewWillEnter() {
    this.canUpdate = this.session.hasFunction(UPDATE_PAYMENT_TYPE)
  }

  save() {
    ask("¿Está seguro?", () => {
        this.enabled = false
        updateModel(this.formGroup, this.paymentType)
        this.manager.updatePaymentType(this.paymentType)
          .subscribe(() => {
            this.navController.back()
          }, (e) => {
            this.enabled = true
          })
    })
  }

  cancel() {
    ask("¿Está seguro?", () => {
        this.navController.back()
    })
  }
}
