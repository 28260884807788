import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { FormGroup } from '@angular/forms'
import { NavController } from '@ionic/angular'

import { Session } from 'system-frontend'
import { ask, buildFormGroup, updateFormGroup, updateModel } from 'system-frontend'

import { PaymentCompanyDetailDto } from '../service/dto/payment.company.detail.dto'
import { MpCompanyDto } from '../service/dto/mp.company.dto'
import { MpCompanyDetailDto } from '../service/dto/mp.company.detail.dto'
import { MpClientDto } from '../service/dto/mp.client.dto'
import { MpNotificationManager } from '../business/mp.notification.manager'
import { NotificationManager } from '../business/notification.manager'
import { UPDATE_MP_COMPANY } from '../business/mp.notification.functions'

@Component({
  templateUrl: 'mp.company.edit.page.html'
})
export class MpCompanyEditPage {
  tmpMpCompany: MpCompanyDto
  mpCompany: MpCompanyDto
  enabled: boolean = true
  paymentCompanies: PaymentCompanyDetailDto[]
  mpClients: MpClientDto[]
  canUpdate: boolean
  formGroup: FormGroup

  constructor(router: Router,
              private navController: NavController,
              private session: Session,
              private manager: MpNotificationManager,
              notificationManager: NotificationManager) {
      this.enabled = false
      this.formGroup = buildFormGroup("paymentCompanyId", "mpClientId")
      if (router.getCurrentNavigation().extras) {
        let mpCompanyDetail = router.getCurrentNavigation().extras as MpCompanyDetailDto
        this.tmpMpCompany = new MpCompanyDto()
        this.tmpMpCompany.id = mpCompanyDetail.id
        this.tmpMpCompany.paymentCompanyId = mpCompanyDetail.paymentCompany.id
        this.tmpMpCompany.mpClientId = mpCompanyDetail.mpClient.id
      }
      this.mpCompany = new MpCompanyDto()

      notificationManager.allPaymentCompanies()
        .subscribe((paymentCompanies) => {
           this.paymentCompanies = paymentCompanies
           this.setMpCompany()
        })
      this.manager.allMpClients()
        .subscribe((mpClients) => {
           this.mpClients = mpClients
           this.setMpCompany()
        })
  }

  ionViewWillEnter() {
    this.canUpdate = this.session.hasFunction(UPDATE_MP_COMPANY)
  }

  save() {
    ask("¿Está seguro?", () => {
        this.enabled = false
        updateModel(this.formGroup, this.mpCompany)
        this.manager.updateMpCompany(this.mpCompany)
          .subscribe(() => {
            this.navController.back()
          }, (e) => {
            this.enabled = true
          })
    })
  }

  cancel() {
    ask("¿Está seguro?", () => {
        this.navController.back()
    })
  }

  private setMpCompany() {
     if (this.paymentCompanies && this.mpClients) {
        this.mpCompany = this.tmpMpCompany
        updateFormGroup(this.mpCompany, this.formGroup)
        this.enabled = true
     }
  }
}
