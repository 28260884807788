import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { StatusExtractor } from 'system-frontend'

import { MpNotificationService } from '../service/mp.notification.service'
import { MpClientDto } from '../service/dto/mp.client.dto'
import { MpCompanyDto } from '../service/dto/mp.company.dto'
import { MpCompanyDetailDto } from '../service/dto/mp.company.detail.dto'

@Injectable({
  providedIn: 'root'
})
export class MpNotificationManager {
  constructor(private service: MpNotificationService,
              private statusExtractor: StatusExtractor) { }

  setClientSecret(clientId: string, secret: string): Observable<void> {
    return this.service.setClientSecret({
                   client: clientId,
                   secret: secret
               })
  }

  setCompanyClient(paymentType: string, companyId: string, clientId: string): Observable<void> {
    return this.service.setCompanyClient({
                   company: {
                     paymentType: paymentType,
                     id: companyId
                   },
                   client: clientId
               })
  }

  allMpClients(): Observable<MpClientDto[]> {
    return this.statusExtractor.extract(this.service.allMpClients())
  }

  updateMpClient(dto: MpClientDto): Observable<any> {
    return this.statusExtractor.extract(this.service.updateMpClient(dto))
  }

  deleteMpClient(id: number): Observable<any> {
    return this.statusExtractor.extract(this.service.deleteMpClient(id))
  }

  allMpCompanies(): Observable<MpCompanyDetailDto[]> {
    return this.statusExtractor.extract(this.service.allMpCompanies())
  }

  updateMpCompany(dto: MpCompanyDto): Observable<any> {
    return this.statusExtractor.extract(this.service.updateMpCompany(dto))
  }

  deleteMpCompany(id: number): Observable<any> {
    return this.statusExtractor.extract(this.service.deleteMpCompany(id))
  }
}
