import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { FormGroup } from '@angular/forms'
import { NavController } from '@ionic/angular'

import { Session } from 'system-frontend'
import { CustomerManager, CustomerDto } from 'system-customer'
import { ask, buildFormGroup, updateFormGroup, updateModel } from 'system-frontend'

import { PaymentCompanyDto } from '../service/dto/payment.company.dto'
import { PaymentCompanyDetailDto } from '../service/dto/payment.company.detail.dto'
import { PaymentTypeDto } from '../service/dto/payment.type.dto'
import { NotificationManager } from '../business/notification.manager'
import { UPDATE_PAYMENT_COMPANY } from '../business/notification.functions'

@Component({
  templateUrl: 'payment.company.edit.page.html'
})
export class PaymentCompanyEditPage {
  tmpPaymentCompany: PaymentCompanyDto
  paymentCompany: PaymentCompanyDto
  enabled: boolean = true
  paymentTypes: PaymentTypeDto[]
  customers: CustomerDto[]
  canUpdate: boolean
  formGroup: FormGroup

  constructor(router: Router,
              private navController: NavController,
              private session: Session,
              private manager: NotificationManager,
              private customerManager: CustomerManager) {
      this.enabled = false
      this.formGroup = buildFormGroup("name", "active", "paymentTypeId", "customerId")
      if (router.getCurrentNavigation().extras) {
        this.tmpPaymentCompany = router.getCurrentNavigation().extras as PaymentCompanyDto

        let companyDetail = router.getCurrentNavigation().extras as PaymentCompanyDetailDto
        this.tmpPaymentCompany = new PaymentCompanyDto()
        this.tmpPaymentCompany.id = companyDetail.id
        this.tmpPaymentCompany.name = companyDetail.name
        this.tmpPaymentCompany.active = companyDetail.active
        this.tmpPaymentCompany.paymentTypeId = companyDetail.paymentType.id
        if (companyDetail.customer) {
          this.tmpPaymentCompany.customerId = companyDetail.customer.id
        }
        this.tmpPaymentCompany.createdAt = companyDetail.createdAt
      }
      this.paymentCompany = new PaymentCompanyDto()

      this.manager.allPaymentTypes()
        .subscribe((paymentTypes) => {
           this.paymentTypes = paymentTypes
           this.setPaymentCompany()
        })
      this.customerManager.allCustomers()
        .subscribe((customers) => {
           this.customers = customers
           this.setPaymentCompany()
        })
  }

  ionViewWillEnter() {
    this.canUpdate = this.session.hasFunction(UPDATE_PAYMENT_COMPANY)
  }

  removeCustomer() {
    updateModel(this.formGroup, this.paymentCompany)
    this.paymentCompany.customerId = null
    updateFormGroup(this.paymentCompany, this.formGroup)
  }

  save() {
    ask("¿Está seguro?", () => {
        this.enabled = false
        updateModel(this.formGroup, this.paymentCompany)
        this.manager.updatePaymentCompany(this.paymentCompany)
          .subscribe(() => {
            this.navController.back()
          }, (e) => {
            this.enabled = true
          })
    })
  }

  cancel() {
    ask("¿Está seguro?", () => {
        this.navController.back()
    })
  }

  private setPaymentCompany() {
     if (this.paymentTypes && this.customers) {
        this.paymentCompany = this.tmpPaymentCompany
        updateFormGroup(this.paymentCompany, this.formGroup)
        this.enabled = true
     }
  }
}
