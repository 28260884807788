import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'
import { HttpClientModule } from '@angular/common/http'

import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'

import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'

import { FrontendModule } from 'system-frontend'
import { SecurityModule } from 'system-security'
import { CustomerModule } from 'system-customer'

import { PaymentTypeListPage } from '../ui/payment.type.list.page'
import { PaymentTypeEditPage } from '../ui/payment.type.edit.page'
import { PaymentCompanyListPage } from '../ui/payment.company.list.page'
import { PaymentCompanyEditPage } from '../ui/payment.company.edit.page'
import { MpClientListPage } from '../ui/mp.client.list.page'
import { MpClientEditPage } from '../ui/mp.client.edit.page'
import { MpCompanyListPage } from '../ui/mp.company.list.page'
import { MpCompanyEditPage } from '../ui/mp.company.edit.page'
import { MpSetClientSecretPage } from '../ui/mp.set.client.secret.page'
import { MpSetCompanyClientPage } from '../ui/mp.set.company.client.page'
import { LogPage } from '../ui/log.page'

@NgModule({
    declarations: [
      AppComponent,
      PaymentTypeListPage,
      PaymentTypeEditPage,
      PaymentCompanyListPage,
      PaymentCompanyEditPage,
      MpClientListPage,
      MpClientEditPage,
      MpCompanyListPage,
      MpCompanyEditPage,
      MpSetClientSecretPage,
      MpSetCompanyClientPage,
      LogPage
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot({ mode: 'md' }),
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        FrontendModule,
        SecurityModule,
        CustomerModule
    ],
    providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
    bootstrap: [AppComponent]
})
export class AppModule {}
