import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { HttpHelper } from 'system-frontend'
import { PayloadDto } from 'system-frontend'

import { PaymentCompanyDto } from './dto/payment.company.dto'
import { PaymentCompanyDetailDto } from './dto/payment.company.detail.dto'
import { PaymentTypeDto } from './dto/payment.type.dto'
import { LogInfoDto } from './dto/log.info.dto'

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private httpHelper: HttpHelper) { }

  getLog(from: Date,
      to: Date,
      companyId: number,
      branch: string,
      host: string): Observable<PayloadDto<LogInfoDto[]>> {

    let params = {
       "from": format(from),
       "to": format(to)
    }
    if (companyId) {
      params["companyId"] = companyId
    }
    if (branch) {
      params["branch"] = branch
    }
    if (host) {
      params["host"] = host
    }
    return this.get<PayloadDto<LogInfoDto[]>>("getLog", params)
  }

  allPaymentTypes(): Observable<PayloadDto<PaymentTypeDto[]>> {
    return this.get<PayloadDto<PaymentTypeDto[]>>("allPaymentTypes")
  }

  updatePaymentType(dto: PaymentTypeDto): Observable<any> {
    return this.post<PayloadDto<any>>("updatePaymentType", dto)
  }

  deletePaymentType(id: number): Observable<PayloadDto<any>> {
    return this.delete<PayloadDto<any>>("deletePaymentType", {id: id})
  }

  allPaymentCompanies(): Observable<PayloadDto<PaymentCompanyDetailDto[]>> {
    return this.get<PayloadDto<PaymentCompanyDetailDto[]>>("allPaymentCompanies")
  }

  updatePaymentCompany(dto: PaymentCompanyDto): Observable<any> {
    return this.post<PayloadDto<any>>("updatePaymentCompany", dto)
  }

  deletePaymentCompany(id: number): Observable<PayloadDto<any>> {
    return this.delete<PayloadDto<any>>("deletePaymentCompany", {id: id})
  }

  private get<X>(path: string, dto: any = {}): Observable<X> {
    return this.httpHelper.get<X>(`/notification/${path}`, dto)
  }

  private post<X>(path: string, dto: any): Observable<X> {
    return this.httpHelper.post<X>(`/notification/${path}`, dto)
  }

  private delete<X>(path: string, dto: any): Observable<X> {
    return this.httpHelper.delete<X>(`/notification/${path}`, dto)
  }
}

function format(date: Date) : string {
  return date.getFullYear() + "-" + pad(date.getMonth() + 1) + "-" + pad(date.getDate())
}

function pad(value: any) : string {
  let str = "00" + value
  return str.substring(str.length - 2)
}
