import { IdDto } from 'system-frontend'
import { CustomerDto } from 'system-customer'
import { PaymentTypeDto } from './payment.type.dto'

export class PaymentCompanyDetailDto extends IdDto  {
    name: string
    active: boolean
    paymentType: PaymentTypeDto
    customer: CustomerDto
    createdAt: Date
}
