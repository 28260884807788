import { Component } from '@angular/core'
import { NavController } from '@ionic/angular'

import { Session } from 'system-frontend'
import { ask } from 'system-frontend'

import { MpCompanyDetailDto } from '../service/dto/mp.company.detail.dto'
import { PaymentCompanyDetailDto } from '../service/dto/payment.company.detail.dto'
import { MpClientDto } from '../service/dto/mp.client.dto'
import { MpNotificationManager } from '../business/mp.notification.manager'
import { DELETE_MP_COMPANY, UPDATE_MP_COMPANY } from '../business/mp.notification.functions'

@Component({
  templateUrl: 'mp.company.list.page.html'
})
export class MpCompanyListPage {
  mpCompanies: MpCompanyDetailDto[]
  canDelete: boolean
  canUpdate: boolean

  constructor(private manager: MpNotificationManager,
              private session: Session,
              private navController: NavController) {
  }

  ionViewWillEnter() {
    this.canDelete = this.session.hasFunction(DELETE_MP_COMPANY)
    this.canUpdate = this.session.hasFunction(UPDATE_MP_COMPANY)
    this.refresh()
  }

  create() {
    let mpCompany = new MpCompanyDetailDto()
    mpCompany.paymentCompany = new PaymentCompanyDetailDto()
    mpCompany.mpClient = new MpClientDto()
    this.edit(mpCompany)
  }

  edit(mpCompany: any) {
    this.navController.navigateForward("mpCompanyEdit", mpCompany)
  }

  delete(mpCompany: MpCompanyDetailDto) {
    ask("¿Está seguro?", () => {
        this.manager.deleteMpCompany(mpCompany.id)
          .subscribe(() => {
             this.refresh()
          })
    })
  }

  private refresh() {
    this.manager.allMpCompanies()
      .subscribe((mpCompanies) => {
         this.mpCompanies = mpCompanies
      })
  }
}
