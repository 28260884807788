import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { FormGroup } from '@angular/forms'
import { NavController } from '@ionic/angular'

import { Session } from 'system-frontend'
import { ask, buildFormGroup, updateFormGroup, updateModel } from 'system-frontend'

import { MpClientDto } from '../service/dto/mp.client.dto'
import { MpNotificationManager } from '../business/mp.notification.manager'
import { UPDATE_MP_CLIENT } from '../business/mp.notification.functions'

@Component({
  templateUrl: 'mp.client.edit.page.html'
})
export class MpClientEditPage {
  mpClient: MpClientDto
  enabled: boolean = true
  canUpdate: boolean
  formGroup: FormGroup

  constructor(router: Router,
              private navController: NavController,
              private session: Session,
              private manager: MpNotificationManager) {
      this.formGroup = buildFormGroup("name", "client", "secret")
      if (router.getCurrentNavigation().extras) {
        this.mpClient = router.getCurrentNavigation().extras as MpClientDto
      } else {
        this.mpClient = new MpClientDto()
      }
      updateFormGroup(this.mpClient, this.formGroup)
  }

  ionViewWillEnter() {
    this.canUpdate = this.session.hasFunction(UPDATE_MP_CLIENT)
  }

  save() {
    ask("¿Está seguro?", () => {
        this.enabled = false
        updateModel(this.formGroup, this.mpClient)
        this.manager.updateMpClient(this.mpClient)
          .subscribe(() => {
            this.navController.back()
          }, (e) => {
            this.enabled = true
          })
    })
  }

  cancel() {
    ask("¿Está seguro?", () => {
        this.navController.back()
    })
  }
}
