import { Component, ElementRef, ViewChild } from '@angular/core'
import { Router, NavigationStart } from '@angular/router'
import { MessageLog, Session, ERROR_BY_CODE } from 'system-frontend'
import { ALL_COMPANIES, ALL_ROLES, ALL_USERS, CHANGE_PASSWORD } from 'system-security'
import { ALL_CUSTOMERS } from 'system-customer'
import { ALL_PAYMENT_COMPANIES, ALL_PAYMENT_TYPES, GET_LOG } from '../business/notification.functions'
import { ALL_MP_CLIENTS, ALL_MP_COMPANIES, SET_CLIENT_SECRET, SET_COMPANY_CLIENT } from '../business/mp.notification.functions'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  logged: boolean
  canCustomerList: boolean
  canPaymentTypeList: boolean
  canPaymentCompanyList: boolean
  canMpClientList: boolean
  canMpCompanyList: boolean
  canSetClientSecret: boolean
  canSetCompanyClient: boolean
  canGetLog: boolean
  canRoleList: boolean
  canCompanyList: boolean
  canUserList: boolean
  canChangePassword: boolean
  canErrorByCode: boolean
  messageColor: string

  constructor(session: Session,
              router: Router,
              private messageLog: MessageLog) {
    router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.logged = session.isLogged()
          this.canPaymentTypeList = session.hasFunction(ALL_PAYMENT_TYPES)
          this.canPaymentCompanyList = session.hasFunction(ALL_PAYMENT_COMPANIES)
          this.canMpClientList = session.hasFunction(ALL_MP_CLIENTS)
          this.canMpCompanyList = session.hasFunction(ALL_MP_COMPANIES)
          this.canSetClientSecret = session.hasFunction(SET_CLIENT_SECRET)
          this.canSetCompanyClient = session.hasFunction(SET_COMPANY_CLIENT)
          this.canGetLog = session.hasFunction(GET_LOG)
          this.canCustomerList = session.hasFunction(ALL_CUSTOMERS)
          this.canRoleList = session.hasFunction(ALL_ROLES)
          this.canCompanyList = session.hasFunction(ALL_COMPANIES)
          this.canUserList = session.hasFunction(ALL_USERS)
          this.canChangePassword = session.hasFunction(CHANGE_PASSWORD)
          this.canErrorByCode = session.hasFunction(ERROR_BY_CODE)
        }
      })
    messageLog.onStateChange((hasErrors: boolean, hasWarnings: boolean) => {
      if (hasErrors) {
        this.messageColor = "danger"
      } else if (hasWarnings) {
        this.messageColor = "warning"
      } else {
        this.messageColor = ""
      }
    })
  }

  showMessages() {
    this.messageLog.show()
  }
}
