import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { StatusExtractor } from 'system-frontend'

import { NotificationService } from '../service/notification.service'
import { PaymentCompanyDto } from '../service/dto/payment.company.dto'
import { PaymentCompanyDetailDto } from '../service/dto/payment.company.detail.dto'
import { PaymentTypeDto } from '../service/dto/payment.type.dto'
import { LogInfoDto } from '../service/dto/log.info.dto'

@Injectable({
  providedIn: 'root'
})
export class NotificationManager {
  constructor(private service: NotificationService,
              private statusExtractor: StatusExtractor) { }

  getLog(from: Date,
      to: Date,
      companyId: number,
      branch: string,
      host: string): Observable<LogInfoDto[]> {
    return this.statusExtractor.extract(this.service.getLog(from, to, companyId, branch, host))
  }

  allPaymentTypes(): Observable<PaymentTypeDto[]> {
    return this.statusExtractor.extract(this.service.allPaymentTypes())
  }

  updatePaymentType(dto: PaymentTypeDto): Observable<any> {
    return this.statusExtractor.extract(this.service.updatePaymentType(dto))
  }

  deletePaymentType(id: number): Observable<any> {
    return this.statusExtractor.extract(this.service.deletePaymentType(id))
  }

  allPaymentCompanies(): Observable<PaymentCompanyDetailDto[]> {
    return this.statusExtractor.extract(this.service.allPaymentCompanies())
  }

  updatePaymentCompany(dto: PaymentCompanyDto): Observable<any> {
    return this.statusExtractor.extract(this.service.updatePaymentCompany(dto))
  }

  deletePaymentCompany(id: number): Observable<any> {
    return this.statusExtractor.extract(this.service.deletePaymentCompany(id))
  }
}
