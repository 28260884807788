import { Component } from '@angular/core'
import { NavController } from '@ionic/angular'

import { Session } from 'system-frontend'
import { ask } from 'system-frontend'

import { MpClientDto } from '../service/dto/mp.client.dto'
import { MpNotificationManager } from '../business/mp.notification.manager'
import { DELETE_MP_CLIENT, UPDATE_MP_CLIENT } from '../business/mp.notification.functions'

@Component({
  templateUrl: 'mp.client.list.page.html'
})
export class MpClientListPage {
  mpClients: MpClientDto[]
  canDelete: boolean
  canUpdate: boolean

  constructor(private manager: MpNotificationManager,
              private session: Session,
              private navController: NavController) {
  }

  ionViewWillEnter() {
    this.canDelete = this.session.hasFunction(DELETE_MP_CLIENT)
    this.canUpdate = this.session.hasFunction(UPDATE_MP_CLIENT)
    this.refresh()
  }

  create() {
    this.edit(new MpClientDto())
  }

  edit(mpClient: any) {
    this.navController.navigateForward("mpClientEdit", mpClient)
  }

  delete(mpClient: MpClientDto) {
    ask("¿Está seguro?", () => {
        this.manager.deleteMpClient(mpClient.id)
          .subscribe(() => {
             this.refresh()
          })
    })
  }

  private refresh() {
    this.manager.allMpClients()
      .subscribe((mpClients) => {
         this.mpClients = mpClients
      })
  }
}
