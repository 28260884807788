import { Component } from '@angular/core'
import { NavController } from '@ionic/angular'

import { PaymentTypeDto } from '../service/dto/payment.type.dto'
import { NotificationManager } from '../business/notification.manager'
import { Session } from 'system-frontend'
import { DELETE_PAYMENT_TYPE, UPDATE_PAYMENT_TYPE } from '../business/notification.functions'
import { ask } from 'system-frontend'

@Component({
  templateUrl: 'payment.type.list.page.html'
})
export class PaymentTypeListPage {
  paymentTypes: PaymentTypeDto[]
  canDelete: boolean
  canUpdate: boolean

  constructor(private manager: NotificationManager,
              private session: Session,
              private navController: NavController) {
  }

  ionViewWillEnter() {
    this.canDelete = this.session.hasFunction(DELETE_PAYMENT_TYPE)
    this.canUpdate = this.session.hasFunction(UPDATE_PAYMENT_TYPE)
    this.refresh()
  }

  create() {
    this.edit(new PaymentTypeDto())
  }

  edit(paymentType: any) {
    this.navController.navigateForward("paymentTypeEdit", paymentType)
  }

  delete(paymentType: PaymentTypeDto) {
    ask("¿Está seguro?", () => {
        this.manager.deletePaymentType(paymentType.id)
          .subscribe(() => {
             this.refresh()
          })
    })
  }

  private refresh() {
    this.manager.allPaymentTypes()
      .subscribe((paymentTypes) => {
         this.paymentTypes = paymentTypes
      })
  }
}
