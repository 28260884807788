import { Component } from '@angular/core'
import { NavController } from '@ionic/angular'

import { Session } from 'system-frontend'
import { ask } from 'system-frontend'
import { CustomerDto } from 'system-customer'

import { PaymentCompanyDetailDto } from '../service/dto/payment.company.detail.dto'
import { PaymentTypeDto } from '../service/dto/payment.type.dto'
import { NotificationManager } from '../business/notification.manager'
import { DELETE_PAYMENT_COMPANY, UPDATE_PAYMENT_COMPANY } from '../business/notification.functions'

@Component({
  templateUrl: 'payment.company.list.page.html'
})
export class PaymentCompanyListPage {
  paymentCompanies: PaymentCompanyDetailDto[]
  canDelete: boolean
  canUpdate: boolean

  constructor(private manager: NotificationManager,
              private session: Session,
              private navController: NavController) {
  }

  ionViewWillEnter() {
    this.canDelete = this.session.hasFunction(DELETE_PAYMENT_COMPANY)
    this.canUpdate = this.session.hasFunction(UPDATE_PAYMENT_COMPANY)
    this.refresh()
  }

  create() {
    let company = new PaymentCompanyDetailDto()
    company.paymentType = new PaymentTypeDto()
    company.customer = new CustomerDto()
    this.edit(company)
  }

  edit(paymentCompany: any) {
    this.navController.navigateForward("paymentCompanyEdit", paymentCompany)
  }

  delete(paymentCompany: PaymentCompanyDetailDto) {
    ask("¿Está seguro?", () => {
        this.manager.deletePaymentCompany(paymentCompany.id)
          .subscribe(() => {
             this.refresh()
          })
    })
  }

  private refresh() {
    this.manager.allPaymentCompanies()
      .subscribe((paymentCompanies) => {
         this.paymentCompanies = paymentCompanies
      })
  }
}
