import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'

import { HomePage } from './home/home.page'

import { ErrorByCodePage } from 'system-frontend'

import { LoginPage } from 'system-security'
import { LogoutPage } from 'system-security'
import { ChangePasswordPage } from 'system-security'
import { CompanyEditPage } from 'system-security'
import { CompanyListPage } from 'system-security'
import { RoleEditPage } from 'system-security'
import { RoleListPage } from 'system-security'
import { UserEditPage } from 'system-security'
import { UserListPage } from 'system-security'
import { CustomerEditPage } from 'system-customer'
import { CustomerListPage } from 'system-customer'
import { PaymentTypeListPage } from '../ui/payment.type.list.page'
import { PaymentTypeEditPage } from '../ui/payment.type.edit.page'
import { PaymentCompanyListPage } from '../ui/payment.company.list.page'
import { PaymentCompanyEditPage } from '../ui/payment.company.edit.page'
import { MpClientListPage } from '../ui/mp.client.list.page'
import { MpClientEditPage } from '../ui/mp.client.edit.page'
import { MpCompanyListPage } from '../ui/mp.company.list.page'
import { MpCompanyEditPage } from '../ui/mp.company.edit.page'
import { LogPage } from '../ui/log.page'
//import { MpSetClientSecretPage } from '../ui/mp.set.client.secret.page'
//import { MpSetCompanyClientPage } from '../ui/mp.set.company.client.page'

const routes: Routes = [
  { path: 'home', component: HomePage },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  { path: 'customerEdit', component: CustomerEditPage },
  { path: 'customerList', component: CustomerListPage },
  { path: 'paymentTypeEdit', component: PaymentTypeEditPage },
  { path: 'paymentTypeList', component: PaymentTypeListPage },
  { path: 'paymentCompanyEdit', component: PaymentCompanyEditPage },
  { path: 'paymentCompanyList', component: PaymentCompanyListPage },
  { path: 'mpClientEdit', component: MpClientEditPage },
  { path: 'mpClientList', component: MpClientListPage },
  { path: 'mpCompanyEdit', component: MpCompanyEditPage },
  { path: 'mpCompanyList', component: MpCompanyListPage },
  //{ path: 'setClientSecret', component: MpSetClientSecretPage },
  //{ path: 'setCompanyClient', component: MpSetCompanyClientPage },
  { path: 'getLog', component: LogPage },
  { path: 'login', component: LoginPage },
  { path: 'logout', component: LogoutPage },
  { path: 'changePassword', component: ChangePasswordPage },
  { path: 'companyEdit', component: CompanyEditPage },
  { path: 'companyList', component: CompanyListPage },
  { path: 'roleEdit', component: RoleEditPage },
  { path: 'roleList', component: RoleListPage },
  { path: 'userEdit', component: UserEditPage },
  { path: 'userList', component: UserListPage },
  { path: 'errorByCode', component: ErrorByCodePage },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
