import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { HttpHelper } from 'system-frontend'
import { PayloadDto } from 'system-frontend'

import { ClientAndSecretDto } from './dto/client.and.secret.dto'
import { CompanyAndClientDto } from './dto/company.and.client.dto'
import { MpClientDto } from './dto/mp.client.dto'
import { MpCompanyDto } from './dto/mp.company.dto'
import { MpCompanyDetailDto } from '../service/dto/mp.company.detail.dto'

@Injectable({
  providedIn: 'root'
})
export class MpNotificationService {
  constructor(private httpHelper: HttpHelper) { }

  setClientSecret(dto: ClientAndSecretDto): Observable<void> {
    return this.post<void>("setClientSecret", dto)
  }

  setCompanyClient(dto: CompanyAndClientDto): Observable<void> {
    return this.post<void>("setCompanyClient", dto)
  }

  allMpClients(): Observable<PayloadDto<MpClientDto[]>> {
    return this.get<PayloadDto<MpClientDto[]>>("allMpClients")
  }

  updateMpClient(dto: MpClientDto): Observable<any> {
    return this.post<PayloadDto<any>>("updateMpClient", dto)
  }

  deleteMpClient(id: number): Observable<PayloadDto<any>> {
    return this.delete<PayloadDto<any>>("deleteMpClient", {id: id})
  }

  allMpCompanies(): Observable<PayloadDto<MpCompanyDetailDto[]>> {
    return this.get<PayloadDto<MpCompanyDetailDto[]>>("allMpCompanies")
  }

  updateMpCompany(dto: MpCompanyDto): Observable<any> {
    return this.post<PayloadDto<any>>("updateMpCompany", dto)
  }

  deleteMpCompany(id: number): Observable<PayloadDto<any>> {
    return this.delete<PayloadDto<any>>("deleteMpCompany", {id: id})
  }

  private get<X>(path: string, dto: any = {}): Observable<X> {
    return this.httpHelper.get<X>(`/notification/mp/${path}`, dto)
  }

  private post<X>(path: string, dto: any): Observable<X> {
    return this.httpHelper.post<X>(`/notification/mp/${path}`, dto)
  }

  private delete<X>(path: string, dto: any): Observable<X> {
    return this.httpHelper.delete<X>(`/notification/mp/${path}`, dto)
  }
}
