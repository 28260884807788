import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { NavController } from '@ionic/angular'
import { NgForm } from '@angular/forms'

import { MpNotificationManager } from '../business/mp.notification.manager'
import { ask } from 'system-frontend'

@Component({
  templateUrl: 'mp.set.client.secret.page.html'
})
export class MpSetClientSecretPage {
  enabled: boolean = true

  constructor(private navController: NavController,
              private manager: MpNotificationManager) {
  }

  setClientSecret(form: NgForm) {
    ask("¿Está seguro?", () => {
        this.enabled = false
        this.manager.setClientSecret(form.value.clientId,
            form.value.secret)
          .subscribe(() => {
            this.navController.back()
          }, (e) => {
            this.enabled = true
          })
    })
  }

  cancel() {
    ask("¿Está seguro?", () => {
        this.navController.back()
    })
  }
}
